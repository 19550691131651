//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Footer',
  data() {
    return {
      icon: undefined,
      shopSection: [],
      showroomSection: [],
      supportSection: [],
      phoneNumber: null,
      emailAddress: null,
      address: null,
    }
  },
  mounted() {
    this.getFooter()
  },
  methods: {
    async getFooter() {
      const footerData = (await this.$axios.get(`/contents/footer`)).data

      this.icon = footerData?.details?.icon
      this.shopSection = footerData?.details?.shop_section
      this.showroomSection = footerData?.details?.showroom_section
      this.supportSection = footerData?.details?.support_section
      this.phoneNumber = footerData?.details?.phone_number?.fieldValue?.value
      this.emailAddress = footerData?.details?.email_address?.fieldValue?.value
      this.address = footerData?.details?.address?.fieldValue?.value

    },
  },
}
